.course-urdu-school {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 10px 10px 10px 10px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 20px;
  overflow: hidden;
  width: 45%;
  flex-direction: column;
}

.courses-container-urdu-school {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 50px;
}

.course-preview-urdu-school {
  background-color: #ffffff;
}

.course-preview-urdu-school img {
  min-height: 360px;
}

.date-certification {
  color: #5eb432;
}
