.home-header {
  width: 100%;
  height: 100vh;
}
.overlay-wapper {
  width: 100%;
  position: relative;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
/* .overlay-wapper img {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: -120px;
  left: -250px;
  overflow: hidden;
} */

.home-header .header-contant {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  color: #ffffff;
  font-family: "Lato", sans-serif;
}
.home-header .header-contant .small-text {
  opacity: 0.2;
  font-weight: 600;
  font-size: 20px;
}
.home-header .header-contant .main-heading {
  font-weight: 600;
  font-family: "Lato", sans-serif;
  font-size: 45px;
}
.home-header .header-contant p {
  margin: 20px 0;
  font-size: 16px;
  color: #ffffff;
}
.home-header .header-contant .header-btn {
  margin: 20px 0;
  padding: 10px 15px;
}

@media (max-width: 992px) {
  .header-contant {
    margin-top: 100px;
  }
}

@media (max-width: 500px) {
  .home-header .header-contant .main-heading {
    font-size: 30px;
  }
}

.header-main {
  margin-top: 70px;
}

@media (max-width: 992px) {
  .header-main {
    margin-top: 20px;
  }
}

.header-slider {
  width: 100%;
  height: 500px;
  margin: 15% auto;
  position: relative;
}

.header-slider .slide {
  height: 410px;
  transform: scale(1, 0.9);
  transition: transform 300ms;
  z-index: -1;
  width: 300px;
}
.slick-current {
  z-index: 2;
}
.slick-slide {
  z-index: -1;
}
.header-slider .activeSlide {
  transform: scale(2.7, 1);
  z-index: 2;
  /* position: absolute;
  z-index: 1; */
}
.header-slider .slide img {
  border-radius: 5%;
  width: 65%;
}
.header-slider .activeSlide img {
  border-radius: 5%;
  width: 65%;
}

.header-slider .slick-dots li button:before {
  opacity: 0.58;
  color: #878787;
  content: "";
  background-color: #878787;
  border-radius: 10px;
  height: 10px;
  width: 10px;
  transition: all ease 200ms;
}
.header-slider .slick-dots li {
  width: 10px;
  height: 10px;
}
.header-slider .slick-dots li.slick-active {
  width: 30px;

  transition: all ease 200ms;
}

.header-slider .slick-dots li.slick-active button:before {
  color: #878787;
  width: 30px;
  transition: all ease;

  transition: all ease 200ms;
}



/* 
=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
* Responsive By Muhammad Adnan
* Wahtsapp : +923007306299
=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/

/* Extra small devices (portrait phones, less than 576px) */
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .covid-section {
    margin-top: 30px !important;
  }
  .header-contant {
    margin-top: 30px;
  }
  .home-header .header-contant .main-heading {
   
    font-size: 32px;
  }
  .header-slider {
    margin: 20% auto;
  }

  .topLeft-img {
    top: 0px;
    left: 0px;
  }
  .topLeft-img, .bottomRight-img {
    width: 47% !important;
    position: initial;
    height: auto !important;
    top: 0px !important;
  }
  
}

@media (max-width: 575.98px) {

  .header-slider .slide{
    height: 30vh;
  }
  
  .header-slider .activeSlide{
    height: 30vh;
  }
  .header-slider{
    height: auto;
  }
  .welcome-parah {
    margin-top: auto;
  }
  .welcome-parah {
    margin-top: 50px;
  }

  
  .covid-info {
    margin-top: 150px !important;
  }
  
  .ad-banner .green-right {
   
    height: 150px;
    width: 46px;
    
  }


  


}

  /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { }


