.left-green {
  position: relative;
}
.left-green::after {
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  position: absolute;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background-color: #5eb432;
}

.card-slider-bg {
  width: 100%;
  height: 68vh;
  margin-top: 30px;

  position: relative;
}
.card-slider-bg .blue-cut {
  position: absolute;
  z-index: 1;
  background-color: #245576;
  clip-path: polygon(0 30%, 0 100%, 100% 100%);
  /* bottom: 0; */
  height: 68vh;
  /* top: 0; */
  left: 0;
  right: 0;
}
.card-slider-bg::before {
  content: "2023";
  position: absolute;
  transform: rotate(270deg);
  color: #3f6a87;
  font-size: 70px;
  bottom: 25%;
  left: 0px;
  margin-left: -30px;
  font-weight: 900;
  z-index: 2;
}
.card-slider {
  position: absolute;
  height: 68vh;
  /* top: 0; */
  /* bottom: 0%; */
  left: 0px;
  right: 0;
  z-index: 2;
}
.card-slider .slick-list {
  margin-left: 130px;
}
.card-slider .card {
  width: 350px;
  height: auto;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}
.card-slider .card .card-img-top {
  padding: 0;
  border-radius: 0;
}
.card-slider .card .card-img-overlay {
  padding: 10px;
}
.card-slider .card .card-img-overlay .card-date {
  display: flex;
  background-color: #5eb432;
  color: #ffffff;
  flex-direction: column;
  width: 42px;
  padding-bottom: 4px;
  text-align: center;
  border-radius: 3px;
  line-height: 1.4;
}
.card-slider .card .card-img-overlay .card-date .month {
  font-size: 11px;
  font-weight: lighter;
}
.card-slider .card .card-img-overlay .card-date .year {
  font-size: 11px;
  font-weight: 500;
}
.card-slider .card .card-img-overlay .card-tille {
  margin: 100px 15px;
  color: #ffffff;
  font-weight: 700;
}
.card-slider .card .card-body {
  padding-bottom: 25px;
}
.card-slider .card .card-body .card-text {
  text-align: center;
  padding: 15px 12px;
  height: 158px;
  /* font-size: 16px; */
}
.card-slider .card .card-body .card-time {
  color: #5eb432;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  padding: 10px 0;
  align-content: center;
}
.card-slider .arrow {
  position: absolute;
  padding: 15px 15px;
  border-radius: 4px;
  top: 0;
}
.card-slider .arrow-next {
  color: #245576;
  background-color: #f2f2f2;
  left: 64px;
}
.card-slider .arrow-prev {
  background-color: #245576;
  color: #ffffff;
  left: 0;
}
.card-slider .css-i4bv87-MuiSvgIcon-root,
.MuiSvgIcon-root {
  font-size: 18px !important;
}
