#reserveEventTable td,
th {
  padding: 5px 50px;
}

@media (max-width: 845px) {
  .long-form {
    width: unset;
  }
}
