.donation-dialog {
  width: 500px;
}
.donation-dialog .form-heading {
  text-align: left;
  padding: 0;
}
.donation-dialog .form-heading h4 {
  font-size: 32px;
}
.donation-dialog .para {
  font-size: 16px;
  text-align: left;
  padding-top: 0;
  line-height: 2;
}
.donation-dialog .form-control {
  margin-bottom: 25px;
  margin-top: 10px;
}
