.sport-bg-img {
  background-position: 0px;
  background-repeat: no-repeat;
  background-size: 75% 90%;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  position: relative;
  left: 0;
}
.sport-bg-img .upper-left {
  position: absolute;
  width: 350px;
  height: 260px;
  top: 70px;
  left: 100px;
}
.sport-bg-img .lower-right {
  position: absolute;
  width: 320px;
  height: 400px;
  right: 30px;
  bottom: 45px;
}

@media (max-width: 992px) {
  .main-program {
    margin-top: 350px;
  }
}

@media (max-width: 500px) {
  .sport-bg-img .upper-left {
    left: 0px;
  }
}



/* 
=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
* Responsive By Muhammad Adnan
* Wahtsapp : +923007306299
=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/

/* Extra small devices (portrait phones, less than 576px) */
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .covid-section {
    margin-top: 25px !important;
  }
  .sport-bg-img .lower-right {
    position: absolute;
    width: 49%;
    height: auto;
    right: 0;
    bottom: 0;
  }
  .sport-bg-img .upper-left {
    position: unset;
    width: 49%;
    height: auto;
    top: 0;
    left: 0;
  }
  
  .main-program{
    margin-top: 10px !important;
  }

  .ftr-nw-ltr .ftr-nw-ltr-inner .ftr-nw-content .ftr-nw-title p {
    font-size: 25px;
    font-weight: 600;
  }

}

@media (max-width: 575.98px) {



  


  .ftr-nw-ltr .ftr-nw-ltr-inner .ftr-nw-content .ftr-nw-title p {
    font-size: 20px;
    font-weight: 600;
  }

}

  /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { }


