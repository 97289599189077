.card-container {
  background-color: #231e39;
  border-radius: 5px;
  box-shadow: 0px 10px 20px -10px rgb(0 0 0 / 75%);
  color: #b3b8cd;
  position: relative;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.card-container img {
  width: 100%;
}
.card-container .pro {
  color: #231e39;
  background-color: #febb0b;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 7px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.card-container .round {
  border: 1px solid #03bfcb;
  border-radius: 50%;
  padding: 7px;
}

button.primary {
  background-color: #03bfcb;
  border: 1px solid #03bfcb;
  border-radius: 3px;
  color: #231e39;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  padding: 10px 25px;
}

button.primary.ghost {
  background-color: transparent;
  color: #02899c;
}

.skills {
  background-color: #1f1a36;
  text-align: left;
  padding: 15px;
  margin-top: 30px;
}

.skills ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.skills ul li {
  border: 1px solid #2d2747;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  margin: 0 7px 7px 0;
  padding: 7px;
}

.sidebar {
  width: 30%;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 15px 20px 0px 20px;
}

.sidebar h1 {
  display: block;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  font-family: "Rubik";
  letter-spacing: 2px;
  font-weight: 400;
  margin: 0;
  font-size: 25px;
  text-transform: uppercase;
}

.sidebar span {
  display: block;
  padding: 10px 20px;
  color: #000000;
  text-decoration: none;
  font-family: "Rubik";
  letter-spacing: 2px;
}

.sidebar span:hover {
  color: #000000;
  margin-left: 20px;
  font-weight: 900;
  transition: 0.4s;
}

.active_item_container {
  display: flex;
}

/* account */

.input_container {
  width: 80%;
  max-width: 100%;
  display: flex;
  margin-bottom: 2%;
}

.input_container > * {
  margin-left: 2%;
}
.accunt_details_input {
  width: 100%;
  border-radius: 5px;
  padding: 3%;
}

@media (max-width: 600px) {
  .input_container {
    width: 60%;
    margin: auto;
    flex-wrap: wrap;
  }
  .c-password {
    margin: auto;
  }
  .change-password-form {
    margin: 0.5rem 2rem !important;
  }
}
