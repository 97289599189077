.strt-list {
  width: 100%;
}
.list-body {
  background-color: #f3f3f3;
  padding: 40px;
  margin-bottom: 2px;
  transition: all ease;
}
.color-body {
  background-image: linear-gradient(to right, #5eb432, #245576);
  transition: all ease;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.color-body .list-heading .list-text {
  color: #ffffff;
  transition: all ease;
}
.list-body .list-heading {
  display: flex;
  font-size: 22px;
  justify-content: space-between;
}
.list-body .list-heading p {
  margin-bottom: 0;
}
.list-body .list-heading .list-title {
  color: #4a4a4a;
}
.list-body .list-heading .css-i4bv87-MuiSvgIcon-root,
.list-body .list-heading .MuiSvgIcon-root {
  background-color: rgba(135, 135, 135, 0.2) !important;
  border-radius: 100%;
  font-size: 20px !important;
  color: #878787 !important;
  height: 40px;
  width: 40px;
}
.color-body .list-heading .css-i4bv87-MuiSvgIcon-root,
.color-body .list-heading .MuiSvgIcon-root {
  color: #ffffff !important;
  transition: all ease;
}
