.about-StatObj .list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 15px 0;
}
.about-StatObj .list .dot {
  /* height: 20px;
  width: 20px; */
  padding: 6px;
  border-radius: 100%;
  background-color: #5eb432;
  margin: 10px 20px 6px 20px;
  justify-self: flex-start;
  align-self: start;
}
