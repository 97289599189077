.modal_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
  width: 50vw;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
}
.circle_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
#outer-circle {
  background: #5eb432;
  border-radius: 50%;
  height: 129px;
  width: 131px;
  position: relative;
}
#inner-circle {
  position: absolute;
  background: #ffffff;
  border-radius: 50%;
  height: 69px;
  width: 69px;
  top: 140%;
  left: 139%;
  margin: -150px 0px 0px -150px;
}
.done_icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 69%;
  left: 16%;
  color: #2f696d;
}
.alert_box_text {
  font-weight: 600;
  font-size: 20px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
}
