.side-image {
  background-position: 0% 30%;
  background-size: 78%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  margin-top: 40px;
  position: relative;
}
.topLeft-img,
.bottomRight-img {
  position: absolute;
  width: 350px;
  height: 350px;
}
.topLeft-img {
  top: 50px;
  left: 0px;
}
.bottomRight-img {
  bottom: 100px;
  right: 100px;
}

@media (max-width: 992px) {
  /* .covid-section {
    margin-top: 400px !important;
  } */
  .bottomRight-img {
    bottom: 100px;
    right: 15px;
  }
  .covid-info {
    margin-top: 446px !important;
  }
}

@media (max-width: 1200px) {
  .container-lg {
    max-width: 100% !important;
  }
}



/* Extra small devices (portrait phones, less than 576px) */
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .covid-section {
    margin-top: 25px !important;
  }
}

@media (max-width: 575.98px) {

  
  .covid-info {
    margin-top: -50px !important;
  }
  


}

  /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { }


