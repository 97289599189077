@import "./Header.css";
@import "./CovidVaccin.css";
@import "./AdBanner.css";
@import "./NewEvents.css";
@import "./SportProgram.css";
@import "./StrategicalObjective.css";
.home-title-name .wt-title {
  font-weight: 700;
}
.home-para {
  font-size: 22px;
  line-height: 1.7;
  margin: 10px 0;
}
.info-link a {
  color: #5eb432;
  font-size: 21px;
  font-weight: 700;
}
.info-link:hover {
  color: #5eb432;
  text-decoration: underline;
}

.info-link .css-i4bv87-MuiSvgIcon-root,
.info-link .MuiSvgIcon-root {
  font-size: 22px !important;
  margin-left: 5px;
  transition: all ease;
}
.clippy {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
}
.clippy .green-clip {
  align-self: flex-end;
  background-color: #6bba42;
  width: 53%;
  height: 50%;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  border-bottom: 30px solid #5eb432;
}
.clippy .blue-clip {
  align-self: flex-start;
  background-color: #356281;
  width: 53%;
  height: 50%;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  /* border-bottom: 30px solid #245576; */
  position: relative;
}
.clippy .blue-clip::after {
  position: absolute;
  content: "";
  width: 100%;
  background-color: #245576;
  bottom: 0px;
  left: 0;
  right: 0;
  top: 0;
  clip-path: polygon(84% 0, 100% 0%, 0 100%, 0 84%);
}
.side-img {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  height: 100%;
  /* border: 2px solid #5eb432; */
}
.side-img img {
  /* height: 450px; */

  height: auto;
  width: 420px;
  margin: 20px 0;
  justify-self: flex-end;
  /* box-shadow: 2px 3px 100px #878787, 2px 3px 20px gainsboro; */
}
.rounded-img {
  border-radius: 40px;

  box-shadow: 4px 5px 50px #ebebeb, 4px 5px 30px #ebebeb;
}

@media (max-width: 992px) {
  /* .welcome-parah {
    margin-top: 500px !important;
  } */
}

@media (max-width: 768px) {
  .home-para {
    font-size: 18px !important;
  }
}




/* 
=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
* Responsive By Muhammad Adnan
* Wahtsapp : +923007306299
=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/

/* Extra small devices (portrait phones, less than 576px) */
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .welcome-parah {
    margin-top: 550px;
  }
  .side-img img{
    width: 100%;
  }
}

@media (max-width: 575.98px) {

  .welcome-parah {
    margin-top: auto;
  }
  .side-img img {
    border-style: none;
    height: inherit;
    width: 100%;
    vertical-align: middle;
    margin: 0;
    justify-self: auto;
  }

}

  /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { }


.header-slider .activeSlide img {
  display: block!important;
}
.header-slider img {
  display: none;
}
