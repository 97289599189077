@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
.community-data-container > * {
  margin-right: 2%;
}

.courses-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-height: 50px;
}
.courses-container > img {
  border-radius: 5%;
  height: 300px;
}
.name-des {
  width: 25%;
  margin-top: 2%;
  padding: 9%;
  position: absolute;
  color: #fff;
  background-color: #ffffffdb;
  border-radius: 6px;
}
.name-des > * {
  margin-right: 2%;
  margin-left: 2%;
}
.course {
  background-color: #fff;
  border-radius: 10px;
  border-left: 5px solid green;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  max-width: 100%;
  margin: 20px;
  overflow: hidden;
  width: 93%;
}

.course h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  color: #000000 !important;
}

.course h2 {
  letter-spacing: 1px;
  margin: 10px 0;
  font-size: 15px;
  color: #000000 !important;
}

.course-preview {
  background-color: #ffffff;
  padding: 30px;
  max-width: 250px;
}

.course-info {
  padding: 30px;
  position: relative;
  width: 100%;
}
