.signUp {
  width: 920px;
  padding-left: 50px;
  padding-right: 50px;
}
.signUp .SignUpAs {
  font-size: 18px;
  font-weight: 600;
}
.signUp .form-heading {
  padding: 30px 0 0px 0;
}
.signUp .form-heading h4 {
  font-size: 35px;
}
.signUp .form-check {
  display: inline-block;
  padding-left: 0;
  padding-right: 20px;
  font-size: 16px;
  margin: 15px 0 20px 0;
}
.signUp .form-check input[Type="radio"] {
  position: absolute;
  opacity: 0;
}
.signUp .form-check input[type="radio"]:checked + label,
.signUp .form-check input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #4a4a4a;
}
.signUp .form-check input[Type="radio"] + .radio-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(214, 214, 214, 0.5);
  border-radius: 100%;
  background: #fff;
}
.signUp .form-check input[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #d6d6d6;
  position: absolute;
  top: 3px;
  left: 3px;
  /* bottom: 4px;
  right: 4px; */
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.signUp .form-check input[type="radio"]:checked + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #5eb432;
  position: absolute;
  top: 3px;
  left: 3px;
  /* bottom: 4px;
    right: 4px; */
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.signUp .form-control {
  margin-bottom: 30px;
}

@media (max-width: 500px) {
  .signup-input {
    display: block !important;
  }
}
