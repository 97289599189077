.funeral-fund-image-container {
  width: 87%;
  height: 50%;
  margin: auto;
}
.funeral-fund-image-container > img {
  width: 100%;
  height: 450px;
}
.funeral-fund-text-container {
  padding: 2% 0% 1% 0%;
}
.funeral-fund-text {
  font-size: 20px;
}
